<template>
  <div>
    <h2>New Course</h2>
    <form @submit="submit" class="form">
      <label>Course Name <input v-model="newCourse.name" /></label>
      <table>
        <tr>
          <td>Hole</td>
          <td>Par</td>
          <td>Handicap</td>
        </tr>

        <tr v-for="hole in holes" :key="hole.number">
          <td>{{ hole.number }}</td>
          <td><input v-model.number="hole.par" /></td>
          <td><input v-model.number="hole.handicap" /></td>
        </tr>
      </table>

      <hr />
      <a class="button" @click="submit">Create Course</a>
    </form>
  </div>
</template>
<script>
import { db } from "../db";
import _ from "lodash";
export default {
  data: function () {
    return {
      holes: [
        { number: 1 },
        { number: 2 },
        { number: 3 },
        { number: 4 },
        { number: 5 },
        { number: 6 },
        { number: 7 },
        { number: 8 },
        { number: 9 },
      ],
      newCourse: {
        name: "",
      },
    };
  },
  computed: {},
  methods: {
    submit() {
      console.log(this.holes);
      if (_.some(this.holes, (h) => !h.par && !h.handicap)) {
        alert("Fill all fields");
      } else {
        db.collection("courses")
          .add(this.newCourse)
          .then((c) => {
            console.log(c.id);
            this.holes.forEach((h) => {
              h.courseId = c.id;
              db.collection("holes")
                .add(h)
                .then((x) => {
                  console.log(x);
                });
            });
          });
      }
    },
  },
};
</script>