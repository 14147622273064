import Vue from 'vue'
import App from './App.vue'
import { vuexfireMutations,firestoreAction } from 'vuexfire'
import Vuex from 'vuex'
import {db} from './db'
import './site.css';
import VueRouter from 'vue-router'
import Handikappr from './components/Handikappr'
import Outing from './components/Outing'
import OutingList from './components/OutingList'
import NewOuting from './components/NewOuting'
import NewCourse from './components/NewCourse'
import ManagePlayers from './components/ManagePlayers'
import scoringService from './modules/scoringService'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)

const store = new Vuex.Store({
    state: {
        scores: [],
        users: [],
        outings: [],
        holes: [],
        outingStrokes: [],
        courses: [],
      },
      getters:{
        userDetails: (state) => {
          return scoringService.calculateUserDetails(state.scores, state.users)
        }
      },
    mutations: {
      // other mutations
      ...vuexfireMutations,
    }, 
    actions: {
        bindScores: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('scores', db.collection('scores'))
        }),
        bindUsers: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('users', db.collection('users'))
        }),
        bindOutings: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('outings', db.collection('outings'))
        }),
        bindHoles: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('holes', db.collection('holes'))
        }),
        bindOutingStrokes: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('outingStrokes', db.collection('outing-strokes'))
        }),
        bindCourses: firestoreAction(({ bindFirestoreRef }) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('courses', db.collection('courses'))
        }),
    },
  })

  const routes = [
    { path: '/', component: Handikappr },

    { path: '/outings', component: OutingList },
    { path: '/outing/:id', component: Outing },
    { path: '/new-outing', component: NewOuting },
    { path: '/new-course', component: NewCourse },
    { path: '/manage-players', component: ManagePlayers }
    
  ]
  const router = new VueRouter({
    routes // short for `routes: routes`
  })
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
