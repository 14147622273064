<template>
  <div>
    <h2>New Outing</h2>
    <form @submit="submit" class="form">
      <label>Outing Name <input v-model="newOuting.name" /></label>
      <hr />
      <div>Who is playing?</div>
      <div v-for="user in users" :key="user.id">
        <label style="text-align: left">
          <input type="checkbox" :value="user.id" v-model="newOuting.users" />
          {{ user.name }}
        </label>
      </div>
      <hr />
      <label>
        Use Dots Scoring
        <input type="checkbox" v-model="newOuting.isUsingDots" />
      </label>
      <label>
        What Course?
        <select v-model="newOuting.courseId">
          <option v-for="c in courses" :value="c.id" :key="c.id">
            {{ c.name }}
          </option>
        </select>
      </label>
      <hr />
      <a class="button" @click="submit">Create Outing</a>
    </form>
  </div>
</template>
<script>
import firebase from "firebase/app";
import { db } from "../db";
export default {
  data: function () {
    return {
      newOuting: {
        isUsingDots: true,
        preName: "",
        sponsor: "Simple Steps Web Development",
        sponsorLink: "https://www.simplestepsweb.com",
        name:
          new Date().getMonth() +
          1 +
          "/" +
          new Date().getDate() +
          "/" +
          new Date().getFullYear() +
          " Outing",
        users: [],
      },
    };
  },
  computed: {
    users: function () {
      return this.$store.state.users;
    },
    courses: function () {
      return this.$store.state.courses;
    },
  },
  methods: {
    submit() {
      console.log(this.newOuting);
      if (
        this.newOuting &&
        this.newOuting.courseId &&
        this.newOuting.users &&
        this.newOuting.users.length > 0
      ) {
        this.newOuting.createdAt =
          firebase.firestore.FieldValue.serverTimestamp();
        db.collection("outings")
          .add(this.newOuting)
          .then((x) => {
            console.log(x);
            this.$router.push("/outings");
          });
      } else {
        alert("Must select players and course");
      }
    },
  },
};
</script>