<template>
  <div>
    <h1>Outings</h1>
    <ul>
      <li v-for="outing in outings" :key="outing.id">
        <a :href="'/#/outing/' + outing.id">{{ outing.name }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  computed: {
    outings: function () {
      // return this.$store.state.outings;
      return _.orderBy(
        this.$store.state.outings,
        (o) => o.createdAt?.seconds,
        "desc"
      );
    },
  },
};
</script>
