<template>
  <div style="text-align: center" v-if="outing">
    <h2>{{ outing.preName }}<br />{{ outing.name }}</h2>
    <h3>
      Presented by <br /><a :href="outing.sponsorLink" target="_blank">{{
        outing.sponsor
      }}</a>
    </h3>
    <div class="scorecard-wrapper">
      <div class="scorecard">
        <table>
          <tr class="holes-row">
            <td class="hole sticky"></td>
            <td class="hole" v-for="hole in holes" :key="hole.number">
              <strong>{{ hole.number }}</strong>
              <br />
              Par {{ hole.par }}
            </td>
            <td class="total">Total</td>
            <td class="total">Pts</td>
          </tr>
          <tr
            class="player-row"
            v-for="ph in playerHoles"
            :key="ph.player.user.name"
          >
            <td class="hole sticky">
              {{ ph.player.user.name }}
              <span v-if="ph.totalDots">({{ ph.totalDots }})</span>
            </td>
            <td
              class="hole"
              v-for="hole in ph.holeDetails"
              :key="hole.hole.number"
            >
              <div class="dots">
                <span> </span>
                <span
                  v-for="index in getIndividualPoints(
                    hole,
                    ph.player.user.name
                  )"
                  :key="index"
                  >✓</span
                >
              </div>
              <div class="score">
                <input
                  v-on:focus="$event.target.select()"
                  v-on:keyup.enter="focusNext($event.target)"
                  :tabindex="hole.hole.number"
                  v-model.number="hole.score"
                  inputmode="numeric"
                  type="number"
                  @change="saveScore(hole, ph.player)"
                />
              </div>
              <div class="dots">
                <span> </span>
                <span v-for="index in hole.dots" :key="index">•</span>
              </div>
            </td>
            <td class="hole total">
              <div class="score">
                {{ getTotal(ph) }}
              </div>
            </td>
            <td class="hole total">
              <div class="score">
                {{ getPoints(ph) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.holes-row,
.player-row {
  /* display:flex; */
  outline: 1px solid #888;
}
.sticky {
  position: absolute;
  top: auto;
  height: 90px;
  width: 50px;
  left: 0;
  border: 1px solid #888;
  transform: translateY(-1px);

  background: #eee;
}

.holes-row .sticky {
  height: 53px;
  width: 51px;
  border-top: none;
  border-left: none;
  background: white;
}
td {
  /* border: 1px solid black;  */
  padding: 5px 10px;
  text-align: center;
}
td:not(:last-child) {
  border-right: 1px solid #888;
}
.total {
  font-weight: bold;
}

.hole.total {
  min-width: 48px;
  font-size: 2rem;
}
.score {
  margin: 10px 0;
  height: 40px;
}
.score input {
  font-size: 2rem;
  width: 35px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
}
.dots {
  height: 15px;
  line-height: 19px;
}
.scorecard-wrapper {
  position: relative;
  margin: auto;
  display: inline-block;
  max-width: calc(100vw - 40px);
}
.scorecard {
  margin-top: 20px;
  overflow-x: auto;
  font-size: 1.2rem;
  margin-left: 70px;
}
</style>
<script>
import _ from "lodash";
import { db } from "../db";
export default {
  methods: {
    focusNext: function (el) {
      var inputs = _.orderBy(
        document.querySelectorAll("input[tabindex]"),
        (i) => i.tabIndex,
        "asc"
      );
      var index = _.indexOf(inputs, el) + 1;
      index = index >= inputs.length ? 0 : index;
      var nextInput = inputs[index];
      nextInput.focus();
      nextInput.select();
    },
    saveScore(hole, player) {
      var existingScore = _.find(
        this.outingStrokes,
        (es) => es.holeId === hole.hole.id && es.userId == player.user.id
      );
      if (existingScore) {
        db.collection("outing-strokes")
          .doc(existingScore.id)
          .update({ strokes: hole.score });
      } else {
        var newScore = {
          outingId: this.outing.id,
          holeId: hole.hole.id,
          strokes: hole.score,
          userId: player.user.id,
        };
        db.collection("outing-strokes").add(newScore);
      }
    },
    getTotal(ph) {
      return _.sumBy(ph.holeDetails, (h) => (h.score ? h.score : 0));
    },
    getIndividualPoints(holeDetails, userName) {
      if (!holeDetails.score) {
        return 0;
      }
      var otherPlayersHoles = _.flatten(
        _.map(
          _.filter(this.playerHoles, (p) => p.player.user.name !== userName),
          (x) => x.holeDetails
        )
      );
      var otherScoresOnThisHole = _.filter(
        otherPlayersHoles,
        (o) => o.hole.number == holeDetails.hole.number
      );
      return _.filter(
        otherScoresOnThisHole,
        (os) => os.score - os.dots > holeDetails.score - holeDetails.dots
      ).length;
    },
    getPoints(ph) {
      var scoresBeaten = 0;

      for (var holeDetails of ph.holeDetails) {
        scoresBeaten += this.getIndividualPoints(
          holeDetails,
          ph.player.user.name
        );
      }
      return scoresBeaten;
    },
    populatePlayerHoles: function () {
      var playerHoles = [];
      var totalDots = 0;
      for (var user of this.userDetails) {
        if (
          typeof this.outing.isUsingDots === "undefined" ||
          this.outing.isUsingDots
        ) {
          totalDots =
            this.holes.length > 9
              ? user.handicap - this.bestHandicap
              : Math.floor((user.handicap - this.bestHandicap) / 2);
        }
        var holeDetails = [];
        for (var hole of this.holes) {
          // var score = _.find(this.scores, s => s.userName === user.user.name && s.holeNumber === hole.number)
          var existingScore = _.find(
            this.outingStrokes,
            (es) => es.holeId === hole.id && es.userId === user.user.id
          );
          holeDetails.push({
            hole: hole,
            score: existingScore ? existingScore.strokes : null,
            dots:
              (totalDots >= hole.handicap ? 1 : 0) +
              (totalDots - this.holes.length > hole.handicap ? 1 : 0),
          });
        }
        playerHoles.push({
          totalDots: totalDots,
          player: user,
          holeDetails: holeDetails,
        });
      }
      this.playerHoles = playerHoles;
    },
  },
  watch: {
    userDetails: function () {
      this.populatePlayerHoles();
    },
    holes: function () {
      this.populatePlayerHoles();
    },
    outingStrokes: function () {
      this.populatePlayerHoles();
    },
  },
  computed: {
    outingStrokes: function () {
      return _.filter(
        this.$store.state.outingStrokes,
        (es) => es.outingId === this.outing.id
      );
    },
    outing: function () {
      return _.find(
        this.$store.state.outings,
        (e) => e.id === this.$route.params.id
      );
    },
    holes: function () {
      return _.orderBy(
        _.filter(
          this.$store.state.holes,
          (h) => h.courseId === this.outing.courseId
        ),
        (h) => h.number
      );
    },
    bestHandicap: function () {
      return _.min(this.userDetails, (u) => u.handicap).handicap;
    },
    userDetails: function () {
      return this.outing
        ? _.filter(this.$store.getters.userDetails, (ud) =>
            _.includes(this.outing.users, ud.user.id)
          )
        : [];
    },
  },
  mounted: function () {
    this.populatePlayerHoles();
  },

  data: function () {
    return {
      playerHoles: [],
      isUsingDots: true,
    };
  },
};
</script>
