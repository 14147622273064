<template>
  <div>
    <h2>Current Players</h2>
    <div v-for="user in userDetails" :key="user.user.id">
      <div>
        {{ user.user.name }}
        <a
          href="javascript:void(0);"
          style="margin-left: 10px"
          @click="deleteUser(user.user.id)"
          >Remove user</a
        >
      </div>
    </div>
    <h2>New Player</h2>
    <form @submit="submit" class="form">
      <label>Name <input v-model="newPlayer.name" /></label>
      <hr />
      <a class="button" @click="submit">Create Player</a>
    </form>
  </div>
</template>
<script>
import { db } from "../db";
export default {
  data: function () {
    return {
      newPlayer: {
        name: "",
      },
    };
  },
  computed: {
    userDetails: function () {
      return this.$store.getters.userDetails;
    },
  },
  methods: {
    deleteUser: function (userId) {
      if (window.confirm("Delete this user?")) {
        db.collection("users").doc(userId).delete();
      }
    },
    submit() {
      if (!this.newPlayer.name) {
        alert("Fill all fields");
      } else {
        db.collection("users")
          .add(this.newPlayer)
          .then(() => {
            alert(`${this.newPlayer.name} added!`);
          });
      }
    },
  },
};
</script>