<template>
  <div>
    <div v-for="user in userDetails" :key="user.id" class="user-content">
      <h2>
        {{ user.user.name }}
        <span class="adv">-{{ Math.floor(user.handicap / 2) }}</span> /
        <span class="adv">-{{ user.handicap }}</span>

        <a class="button add-button" @click="showForm(user.user.id)"
          >Add Score</a
        >
      </h2>
      <h3>
        Using best {{ user.bestScores.length }} of recent
        {{ user.recentScores.length }}
      </h3>

      <form
        class="form"
        @submit.prevent="submitScore(user.user.id)"
        v-if="activeUserForm === user.user.id"
      >
        <h3>New Score</h3>
        <label>
          <span>What was par?</span>
          <input type="number" v-model="newScore.par" />
        </label>
        <label>
          <span>What did you score?</span>
          <input type="number" v-model="newScore.strokes" />
        </label>
        <button class="submit" type="submit">Submit</button>
        <div class="error" v-if="error">
          Something's wrong. Check inputs, try again.
        </div>

        <hr />
      </form>
      <a href="javascript:void(0);" @click="toggleScores(user.user.id)">
        See scores</a
      >
      <div v-if="activeUserScores === user.user.id" class="score-wrapper">
        <score-row
          v-for="scoreDetail in user.scoreDetails"
          :key="scoreDetail.score.id"
          :scoreDetail="scoreDetail"
          :isBest="isBest(user.bestScores, scoreDetail)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { db } from "../db";
import firebase from "firebase/app";
import "firebase/firestore";
import ScoreRow from "./ScoreRow.vue";

export default {
  components: { ScoreRow },
  data() {
    return {
      showMenu: false,
      activeUserForm: null,
      activeUserScores: null,
      error: false,
    };
  },
  computed: {
    userDetails: function () {
      return this.$store.getters.userDetails;
    },
  },
  methods: {
    toggleScores: function (userId) {
      if (this.activeUserScores === userId) {
        this.activeUserScores = null;
        return;
      }
      this.activeUserScores = userId;
    },
    isBest: function (bestScores, scoreDetail) {
      return _.some(bestScores, (s) => s.score.id == scoreDetail.score.id);
    },

    showForm: function (userId) {
      if (this.activeUserForm === userId) {
        this.activeUserForm = null;
        return;
      }
      this.activeUserForm = userId;
      this.newScore = {};
    },
    submitScore: function (userId) {
      this.error = false;
      if (this.newScore.par && this.newScore.strokes) {
        this.newScore.userId = userId;
        (this.newScore.createdAt =
          firebase.firestore.FieldValue.serverTimestamp()),
          db
            .collection("scores")
            .add(this.newScore)
            .then(() => {
              this.activeUserForm = null;
            });
      } else {
        this.error = true;
      }
    },
  },
};
</script>