<template>
  <div class="score" @click="showOptions()">
    <span :class="isBest ? 'best' : 'not-best'">
      <em>{{ formatTimestamp(scoreDetail.score.createdAt) }}:</em>
      Shot a {{ scoreDetail.score.strokes }} on a par
      {{ scoreDetail.score.par }}
      <span v-if="scoreDetail.netScore">(net {{ scoreDetail.netScore }})</span>
    </span>
    <div v-if="optionsShown" class="options">
      <ul v-if="scoreDetail.netScore">
        <li>Handicap at the time: {{ scoreDetail.runningHandicap }}</li>
        <li>Score: {{ scoreDetail.score.strokes }}</li>
        <li>Score Adjustment: -{{ scoreDetail.adjustment }}</li>
        <li>Normalized Score: {{ scoreDetail.normalizedScore }}</li>
        <li>Net Score: {{ scoreDetail.netScore }}</li>
      </ul>
      <a class="button remove-button" @click="deleteScore()">Remove Score</a>
    </div>
  </div>
</template>
<script>
import { db } from "../db";
export default {
  props: {
    scoreDetail: {
      type: Object,
      required: true,
    },
    isBest: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      optionsShown: false,
    };
  },
  methods: {
    deleteScore: function () {
      if (window.confirm("Delete this score?")) {
        db.collection("scores").doc(this.scoreDetail.score.id).delete();
      }
    },
    formatTimestamp: function (ts) {
      var date = ts.toDate();
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    },
    showOptions: function () {
      this.optionsShown = !this.optionsShown;
    },
  },
};
</script>
<style scoped>
.best {
  font-weight: bold;
}
.not-best {
  text-decoration: line-through;
}
.options {
  text-decoration: none;
}
</style>