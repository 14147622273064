<template>
  <div id="app">
    <div class="header">
      <div class="row">
        <h1 @click="$router.push('/')">Handikappr</h1>
        <span class="menu-icon" @click="toggleMenu">☰</span>
      </div>
      <div class="menu" v-if="showMenu">
        <a href="/#/outings" @click="toggleMenu">Outings</a>
        <a href="/#/new-outing" @click="toggleMenu">New Outing</a>
        <a href="/#/new-course" @click="toggleMenu">New Course</a>
        <a href="/#/manage-players" @click="toggleMenu">Manage Players</a>
      </div>
    </div>
    <div class="page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu: function () {
      this.showMenu = !this.showMenu;
    },
  },
  components: {},
  mounted: function () {
    this.$store.dispatch("bindScores");
    this.$store.dispatch("bindUsers");
    this.$store.dispatch("bindCourses");
    this.$store.dispatch("bindOutings");
    this.$store.dispatch("bindOutingStrokes");
    this.$store.dispatch("bindHoles");
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body,
html {
  padding: 0;
  margin: 0;
}
</style>
