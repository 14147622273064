
import _ from "lodash";
var getNumScoresToUse = function (length) {
  //WGA 8-of-20 rule
  if (length < 6) {
    return 1;
  }
  if (length < 9) {
    return 2;
  }
  if (length < 12) {
    return 3;
  }
  if (length < 15) {
    return 4;
  }
  if (length < 17) {
    return 5;
  }
  if (length < 19) {
    return 6;
  }
  if (length < 20) {
    return 7;
  }
  return 8;
};
var getRecentScores = function (scores) {
  //Take 20 recent scores (8-of-20 rule)
  return _.take(
    _.orderBy(scores, (s) => s.score.createdAt?.seconds, "desc"),
    20
  );
};
var getBestScores = function (scores) {
  var recentScores = getRecentScores(scores);
  return _.take(
    _.orderBy(recentScores, "normalizedScore", "asc"),
    getNumScoresToUse(scores.length)
  );
};
var getAverageOfBest = function (scores) {
  return Math.floor(_.meanBy(getBestScores(scores), "normalizedScore"));
};
var getHandicapFromScores = function (scores) {
  return scores.length > 0 ? getAverageOfBest(scores) - 72 : 99;
};
export default {
  calculateUserDetails: function (scores, users) {
    var userDetails = [];
    var scoresByUser = {};
    for (var score of scores) {
      if (!scoresByUser[score.userId]) {
        scoresByUser[score.userId] = [];
      }
      var runningScores = scoresByUser[score.userId];
      var runningHandicap = getHandicapFromScores(runningScores);
      var adjustment = Math.floor(runningHandicap * (score.par / 72));
      var scoreDetail = {
        score: score,
        normalizedScore: Math.floor((72 / score.par) * score.strokes),
        runningHandicap: runningHandicap,
        adjustment: adjustment,
        netScore:
          runningScores.length > 0 ? score.strokes - adjustment : null,
      };

      scoresByUser[score.userId].push(scoreDetail);
    }
    for (var user of users) {
      var userScores = _.orderBy(scoresByUser[user.id], s=> s.score.createdAt?.seconds, "desc");
      var recentScores = getRecentScores(userScores);
      var bestUserScores = getBestScores(userScores);
      var userDetail = {
        user: user,
        scoreDetails: userScores,
        bestScores: bestUserScores,
        recentScores: recentScores,
      };
      userDetail.handicap = getHandicapFromScores(userDetail.scoreDetails);

      userDetails.push(userDetail);
    }
    userDetails = _.orderBy(userDetails, "handicap", "asc");
    return userDetails;
  },
}